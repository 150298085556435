<template>
  <article id="container" class="home-container">
    <section class="home-try">
      <div class="home-try-star">
        <i class="el-icon-star-on"></i>
        <i class="el-icon-star-on"></i>
        <i class="el-icon-star-on"></i>
        <i class="el-icon-star-on"></i>
        <i class="el-icon-star-on"></i>
      </div>
      <h3>$0.35/GB for Premium Proxies</h3>
      <h3>Exceptional Quality at Unbeatable Prices</h3>
      <ul class="home-try-advantage">
        <li><i class="el-icon-check"></i><span>700+ Customers</span></li>
        <li><i class="el-icon-check"></i><span>60M+  Residential & DC IPs</span></li>
        <li><i class="el-icon-check"></i><span>195 Countries</span></li>
      </ul>
      <div class="home-try-now">
        <el-button type="success" @click="tryNow">Try Now</el-button>
      </div>
    </section>
    <el-tabs id="Price" ref="Price" class="home-price-tabs" v-model="activeTab" type="card" @tab-click="switchTab">
      <el-tab-pane label="Residential" name="Residential">
        <section class="home-price">
          <div class="home-price-item">
            <ul class="home-price-equity home-price-mequity">
              <li class="traffic">Traffic</li>
              <li class="amount">IP amount</li>
              <li class="accounts">Sub-accounts</li>
              <li class="country">Country selection</li>
<!--              <li class="dedicated">Dedicated server</li>-->
            </ul>
          </div>
          <div class="home-price-item" v-for="(item, index) in residentials" :key="index" :class="index === curResidentialIndex ? 'active' : ''" @click="choosePriceItem('Residential', index)">
            <div v-if="item.popular" class="popular">{{item.popular}}</div>
            <div v-if="item.discount" class="discount">{{item.discount}}</div>
            <div v-else class="discount-p"></div>
            <div class="title">{{item.title}}</div>
            <div class="price">
              <span class="activity">${{item.activityPrice}}</span>
<!--              <span class="original">/<span :class="index > 0 ? 'dline' : ''"><span v-if="index > 0">$</span></span></span>-->
            </div>
            <div class="days">
              <span class="activity">{{item.days}}Days</span>
            </div>
<!--            <div v-else class="typrice">${{item.activityPrice}}</div>-->
            <div class="unit"><span class="tag">{{item.unitPrice}}</span></div>
            <a class="buy" href="javascript:;" @click="bugPlan(item)">Buy Plan</a>
            <ul class="home-price-equity home-price-lequity">
              <li>{{item.traffic}}</li>
              <li>{{item.amount}}</li>
              <li><i :class="item.accounts ? 'el-icon-check' : 'el-icon-minus'"></i></li>
              <li><i :class="item.country ? 'el-icon-check' : 'el-icon-minus'"></i></li>
<!--              <li><i :class="item.dedicated ? 'el-icon-check' : 'el-icon-minus'"></i></li>-->
            </ul>
          </div>
        </section>
      </el-tab-pane>
      <el-tab-pane label="Datacenter" name="Datacenter">
        <section class="home-price">
          <div class="home-price-item">
            <ul class="home-price-equity home-price-mequity">
              <li class="traffic">Traffic</li>
              <li class="amount">IP amount</li>
              <li class="accounts">Sub-accounts</li>
              <li class="country">Country selection</li>
<!--              <li class="dedicated">Dedicated server</li>-->
            </ul>
          </div>
          <div class="home-price-item" v-for="(item, index) in datacenters" :key="index" :class="index === curDatacenterIndex ? 'active' : ''" @click="choosePriceItem('Datacenter', index)">
            <div v-if="item.popular" class="popular">{{item.popular}}</div>
            <div v-if="item.discount" class="discount">{{item.discount}}</div>
            <div v-else class="discount-p"></div>
            <div class="title">{{item.title}}</div>
            <div class="price">
              <span class="activity">${{item.activityPrice}}</span>
<!--              <span class="original">/<span :class="index > 0 ? 'dline' : ''"><span v-if="index > 0">$</span></span></span>-->
            </div>
            <div  class="days">
              <span class="activity">{{item.days}}Days</span>
            </div>
<!--            <div v-else class="typrice">${{item.activityPrice}}</div>-->
            <div class="unit"><span class="tag">{{item.unitPrice}}</span></div>
            <a class="buy" href="javascript:;" @click="bugPlan(item)">Buy Plan</a>
            <ul class="home-price-equity home-price-lequity">
              <li>{{item.traffic}}</li>
              <li>{{item.amount}}</li>
              <li><i :class="item.accounts ? 'el-icon-check' : 'el-icon-minus'"></i></li>
              <li><i :class="item.country ? 'el-icon-check' : 'el-icon-minus'"></i></li>
<!--              <li><i :class="item.dedicated ? 'el-icon-check' : 'el-icon-minus'"></i></li>-->
            </ul>
          </div>
        </section>
      </el-tab-pane>
    </el-tabs>
    <section id="ProductResidential" ref="ProductResidential" class="home-product">
      <section class="residential-info">
        <header class="residential-info-title">Unmatched Statistics</header>
        <article class="residential-info-box">
          <dl class="residential-info-item">
            <dt>
              <span class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M20.6201 8.45C19.5701 3.83 15.5401 1.75 12.0001 1.75C12.0001 1.75 12.0001 1.75 11.9901 1.75C8.4601 1.75 4.4201 3.82 3.3701 8.44C2.2001 13.6 5.3601 17.97 8.2201 20.72C9.2801 21.74 10.6401 22.25 12.0001 22.25C13.3601 22.25 14.7201 21.74 15.7701 20.72C18.6301 17.97 21.7901 13.61 20.6201 8.45Z" fill="#FFDAAD"></path>
                  <path d="M11.9996 13.4602C13.7393 13.4602 15.1496 12.0499 15.1496 10.3102C15.1496 8.57046 13.7393 7.16016 11.9996 7.16016C10.2599 7.16016 8.84961 8.57046 8.84961 10.3102C8.84961 12.0499 10.2599 13.4602 11.9996 13.4602Z" fill="#FFA232"></path>
                </svg>
              </span>
              <h6>60M+</h6>
            </dt>
            <dd>Unique IPs</dd>
          </dl>
          <dl class="residential-info-item">
            <dt>
              <span class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M10.9598 2.05959L5.4598 4.11959C4.4098 4.51959 3.5498 5.75959 3.5498 6.88959V14.9896C3.5498 15.7996 4.0798 16.8696 4.7298 17.3496L10.2298 21.4596C11.1998 22.1896 12.7898 22.1896 13.7598 21.4596L19.2598 17.3496C19.9098 16.8596 20.4398 15.7996 20.4398 14.9896V6.88959C20.4398 5.76959 19.5798 4.51959 18.5298 4.12959L13.0298 2.06959C12.4698 1.84959 11.5298 1.84959 10.9598 2.05959Z" fill="#FFDAAD"></path>
                  <path d="M10.6602 14.2301C10.4702 14.2301 10.2802 14.1601 10.1302 14.0101L8.52023 12.4001C8.23023 12.1101 8.23023 11.6301 8.52023 11.3401C8.81023 11.0501 9.29023 11.0501 9.58023 11.3401L10.6602 12.4201L14.4302 8.65012C14.7202 8.36012 15.2002 8.36012 15.4902 8.65012C15.7802 8.94012 15.7802 9.42012 15.4902 9.71012L11.1902 14.0101C11.0402 14.1601 10.8502 14.2301 10.6602 14.2301Z" fill="#FFA232"></path>
                </svg>
              </span>
              <h6>99.9%</h6>
            </dt>
            <dd>Success Rate</dd>
          </dl>
          <dl class="residential-info-item">
            <dt>
              <span class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M12.0001 22.0002C16.7884 22.0002 20.6701 18.1185 20.6701 13.3302C20.6701 8.54185 16.7884 4.66016 12.0001 4.66016C7.21177 4.66016 3.33008 8.54185 3.33008 13.3302C3.33008 18.1185 7.21177 22.0002 12.0001 22.0002Z" fill="#FFDAAD"></path>
                  <path d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z" fill="#FFA232"></path>
                  <path d="M14.8896 3.45H9.10965C8.70965 3.45 8.38965 3.13 8.38965 2.73C8.38965 2.33 8.70965 2 9.10965 2H14.8896C15.2896 2 15.6096 2.32 15.6096 2.72C15.6096 3.12 15.2896 3.45 14.8896 3.45Z" fill="#FFA232"></path>
                </svg>
              </span>
              <h6>&lt;1s</h6>
            </dt>
            <dd>Response Time</dd>
          </dl>
          <dl class="residential-info-item">
            <dt>
              <span class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M17.53 7.77C17.46 7.76 17.39 7.76 17.32 7.77C15.77 7.72 14.54 6.45 14.54 4.89C14.54 3.3 15.83 2 17.43 2C19.02 2 20.32 3.29 20.32 4.89C20.31 6.45 19.08 7.72 17.53 7.77Z" fill="#FFDAAD"></path>
                  <path opacity="0.4" d="M20.7896 14.7004C19.6696 15.4504 18.0996 15.7304 16.6496 15.5404C17.0296 14.7204 17.2296 13.8104 17.2396 12.8504C17.2396 11.8504 17.0196 10.9004 16.5996 10.0704C18.0796 9.8704 19.6496 10.1504 20.7796 10.9004C22.3596 11.9404 22.3596 13.6504 20.7896 14.7004Z" fill="#FFDAAD"></path>
                  <path opacity="0.4" d="M6.44039 7.77C6.51039 7.76 6.58039 7.76 6.65039 7.77C8.20039 7.72 9.43039 6.45 9.43039 4.89C9.43039 3.3 8.14039 2 6.54039 2C4.95039 2 3.65039 3.29 3.65039 4.89C3.66039 6.45 4.89039 7.72 6.44039 7.77Z" fill="#FFDAAD"></path>
                  <path opacity="0.4" d="M6.55012 12.8496C6.55012 13.8196 6.76012 14.7396 7.14012 15.5696C5.73012 15.7196 4.26012 15.4196 3.18012 14.7096C1.60012 13.6596 1.60012 11.9496 3.18012 10.8996C4.25012 10.1796 5.76012 9.88962 7.18012 10.0496C6.77012 10.8896 6.55012 11.8396 6.55012 12.8496Z" fill="#FFDAAD"></path>
                  <path d="M12.1198 15.87C12.0398 15.86 11.9498 15.86 11.8598 15.87C10.0198 15.81 8.5498 14.3 8.5498 12.44C8.5498 10.54 10.0798 9 11.9898 9C13.8898 9 15.4298 10.54 15.4298 12.44C15.4298 14.3 13.9698 15.81 12.1198 15.87Z" fill="#FFA232"></path>
                  <path d="M8.8698 17.9396C7.3598 18.9496 7.3598 20.6096 8.8698 21.6096C10.5898 22.7596 13.4098 22.7596 15.1298 21.6096C16.6398 20.5996 16.6398 18.9396 15.1298 17.9396C13.4198 16.7896 10.5998 16.7896 8.8698 17.9396Z" fill="#FFA232"></path>
                </svg>
              </span>
              <h6>700+</h6>
            </dt>
            <dd>Customers</dd>
          </dl>
        </article>
      </section>
    </section>
    <section class="home-product">
      <div class="home-product-box">
        <img src="@/images/background/product-residential.png" width="100%" alt="" />
      </div>
      <div class="home-product-box">
        <div class="home-product-head">
          <span class="icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.4" d="M17.53 7.77C17.46 7.76 17.39 7.76 17.32 7.77C15.77 7.72 14.54 6.45 14.54 4.89C14.54 3.3 15.83 2 17.43 2C19.02 2 20.32 3.29 20.32 4.89C20.31 6.45 19.08 7.72 17.53 7.77Z" fill="#FFA232"></path>
              <path opacity="0.4" d="M20.7896 14.6999C19.6696 15.4499 18.0996 15.7299 16.6496 15.5399C17.0296 14.7199 17.2296 13.8099 17.2396 12.8499C17.2396 11.8499 17.0196 10.8999 16.5996 10.0699C18.0796 9.86992 19.6496 10.1499 20.7796 10.8999C22.3596 11.9399 22.3596 13.6499 20.7896 14.6999Z" fill="#FFA232"></path>
              <path opacity="0.4" d="M6.44039 7.77C6.51039 7.76 6.58039 7.76 6.65039 7.77C8.20039 7.72 9.43039 6.45 9.43039 4.89C9.43039 3.3 8.14039 2 6.54039 2C4.95039 2 3.65039 3.29 3.65039 4.89C3.66039 6.45 4.89039 7.72 6.44039 7.77Z" fill="#FFA232"></path>
              <path opacity="0.4" d="M6.55012 12.8501C6.55012 13.8201 6.76012 14.7401 7.14012 15.5701C5.73012 15.7201 4.26012 15.4201 3.18012 14.7101C1.60012 13.6601 1.60012 11.9501 3.18012 10.9001C4.25012 10.1801 5.76012 9.8901 7.18012 10.0501C6.77012 10.8901 6.55012 11.8401 6.55012 12.8501Z" fill="#FFA232"></path>
              <path d="M12.1198 15.87C12.0398 15.86 11.9498 15.86 11.8598 15.87C10.0198 15.81 8.5498 14.3 8.5498 12.44C8.5498 10.54 10.0798 9 11.9898 9C13.8898 9 15.4298 10.54 15.4298 12.44C15.4298 14.3 13.9698 15.81 12.1198 15.87Z" fill="#FFA232"></path>
              <path d="M8.8698 17.9401C7.3598 18.9501 7.3598 20.6101 8.8698 21.6101C10.5898 22.7601 13.4098 22.7601 15.1298 21.6101C16.6398 20.6001 16.6398 18.9401 15.1298 17.9401C13.4198 16.7901 10.5998 16.7901 8.8698 17.9401Z" fill="#FFA232"></path>
            </svg>
          </span>
          <span class="title">ETHICALLY SOURCED NETWORK</span>
        </div>
        <h2>We financially reward our peers</h2>
        <p class="home-product-text">Our approach to residential proxies is rooted in transparency and ethics. We believe in a fair and ethical partnership with our peers, who are fully aware of our activities. We ensure that everyone involved is fairly compensated for their contributions, fostering a sense of trust and responsibility within our network.</p>
        <ul class="home-product-adv residential">
          <li>
            <i class="el-icon-check"></i>
            <span class="title">Financial reward</span>
          </li>
          <li>
            <i class="el-icon-check"></i>
            <span class="title">User consent</span>
          </li>
          <li>
            <i class="el-icon-check"></i>
            <span class="title">User awareness</span>
          </li>
        </ul>
        <a class="btn-self residential" href="javascript:;" @click="buySelf('/home?column=Price&category=Residential')">
          <svg data-v-1ee8bc0b="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" tag="i" class="v-icon notranslate v-theme--dark v-icon--size-default iconify iconify--mdi" width="1em" height="1em" viewBox="0 0 24 24" style="width: 16px; margin-right: 5px;">
          <path fill="currentColor" d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25c0-.05.01-.09.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"></path>
          </svg>Self-checkout
        </a>
      </div>
    </section>
    <section id="ProductDatacenter" ref="ProductDatacenter" class="home-product">
      <section class="datacenter-info">
        <header class="datacenter-info-title">Unmatched Statistics</header>
        <article class="datacenter-info-box">
          <dl class="datacenter-info-item">
            <dt>
              <span class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M20.6201 8.45C19.5701 3.83 15.5401 1.75 12.0001 1.75C12.0001 1.75 12.0001 1.75 11.9901 1.75C8.4601 1.75 4.4201 3.82 3.3701 8.44C2.2001 13.6 5.3601 17.97 8.2201 20.72C9.2801 21.74 10.6401 22.25 12.0001 22.25C13.3601 22.25 14.7201 21.74 15.7701 20.72C18.6301 17.97 21.7901 13.61 20.6201 8.45Z" fill="#99CBFF"></path>
                  <path d="M11.9996 13.4602C13.7393 13.4602 15.1496 12.0499 15.1496 10.3102C15.1496 8.57046 13.7393 7.16016 11.9996 7.16016C10.2599 7.16016 8.84961 8.57046 8.84961 10.3102C8.84961 12.0499 10.2599 13.4602 11.9996 13.4602Z" fill="#017EFF"></path>
                </svg>
              </span>
              <h6>100</h6>
            </dt>
            <dd>Million Residential IPs</dd>
          </dl>
          <dl class="datacenter-info-item">
            <dt>
              <span class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M10.9598 2.05959L5.4598 4.11959C4.4098 4.51959 3.5498 5.75959 3.5498 6.88959V14.9896C3.5498 15.7996 4.0798 16.8696 4.7298 17.3496L10.2298 21.4596C11.1998 22.1896 12.7898 22.1896 13.7598 21.4596L19.2598 17.3496C19.9098 16.8596 20.4398 15.7996 20.4398 14.9896V6.88959C20.4398 5.76959 19.5798 4.51959 18.5298 4.12959L13.0298 2.06959C12.4698 1.84959 11.5298 1.84959 10.9598 2.05959Z" fill="#99CBFF"></path>
                  <path d="M10.6602 14.2301C10.4702 14.2301 10.2802 14.1601 10.1302 14.0101L8.52023 12.4001C8.23023 12.1101 8.23023 11.6301 8.52023 11.3401C8.81023 11.0501 9.29023 11.0501 9.58023 11.3401L10.6602 12.4201L14.4302 8.65012C14.7202 8.36012 15.2002 8.36012 15.4902 8.65012C15.7802 8.94012 15.7802 9.42012 15.4902 9.71012L11.1902 14.0101C11.0402 14.1601 10.8502 14.2301 10.6602 14.2301Z" fill="#017EFF"></path>
                </svg>
              </span>
              <h6>99.9%</h6>
            </dt>
            <dd>Success Rate</dd>
          </dl>
          <dl class="datacenter-info-item">
            <dt>
              <span class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M12.0001 22.0002C16.7884 22.0002 20.6701 18.1185 20.6701 13.3302C20.6701 8.54185 16.7884 4.66016 12.0001 4.66016C7.21177 4.66016 3.33008 8.54185 3.33008 13.3302C3.33008 18.1185 7.21177 22.0002 12.0001 22.0002Z" fill="#99CBFF"></path>
                  <path d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z" fill="#017EFF"></path>
                  <path d="M14.8896 3.45H9.10965C8.70965 3.45 8.38965 3.13 8.38965 2.73C8.38965 2.33 8.70965 2 9.10965 2H14.8896C15.2896 2 15.6096 2.32 15.6096 2.72C15.6096 3.12 15.2896 3.45 14.8896 3.45Z" fill="#017EFF"></path>
                </svg>
              </span>
              <h6>0.5s</h6>
            </dt>
            <dd>Response Time</dd>
          </dl>
          <dl class="datacenter-info-item">
            <dt>
              <span class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M17.53 7.77C17.46 7.76 17.39 7.76 17.32 7.77C15.77 7.72 14.54 6.45 14.54 4.89C14.54 3.3 15.83 2 17.43 2C19.02 2 20.32 3.29 20.32 4.89C20.31 6.45 19.08 7.72 17.53 7.77Z" fill="#99CBFF"></path>
                  <path opacity="0.4" d="M20.7896 14.7004C19.6696 15.4504 18.0996 15.7304 16.6496 15.5404C17.0296 14.7204 17.2296 13.8104 17.2396 12.8504C17.2396 11.8504 17.0196 10.9004 16.5996 10.0704C18.0796 9.8704 19.6496 10.1504 20.7796 10.9004C22.3596 11.9404 22.3596 13.6504 20.7896 14.7004Z" fill="#99CBFF"></path>
                  <path opacity="0.4" d="M6.44039 7.77C6.51039 7.76 6.58039 7.76 6.65039 7.77C8.20039 7.72 9.43039 6.45 9.43039 4.89C9.43039 3.3 8.14039 2 6.54039 2C4.95039 2 3.65039 3.29 3.65039 4.89C3.66039 6.45 4.89039 7.72 6.44039 7.77Z" fill="#99CBFF"></path>
                  <path opacity="0.4" d="M6.55012 12.8496C6.55012 13.8196 6.76012 14.7396 7.14012 15.5696C5.73012 15.7196 4.26012 15.4196 3.18012 14.7096C1.60012 13.6596 1.60012 11.9496 3.18012 10.8996C4.25012 10.1796 5.76012 9.88962 7.18012 10.0496C6.77012 10.8896 6.55012 11.8396 6.55012 12.8496Z" fill="#99CBFF"></path>
                  <path d="M12.1198 15.87C12.0398 15.86 11.9498 15.86 11.8598 15.87C10.0198 15.81 8.5498 14.3 8.5498 12.44C8.5498 10.54 10.0798 9 11.9898 9C13.8898 9 15.4298 10.54 15.4298 12.44C15.4298 14.3 13.9698 15.81 12.1198 15.87Z" fill="#017EFF"></path>
                  <path d="M8.8698 17.9396C7.3598 18.9496 7.3598 20.6096 8.8698 21.6096C10.5898 22.7596 13.4098 22.7596 15.1298 21.6096C16.6398 20.5996 16.6398 18.9396 15.1298 17.9396C13.4198 16.7896 10.5998 16.7896 8.8698 17.9396Z" fill="#017EFF"></path>
                </svg>
              </span>
              <h6>700+</h6>
            </dt>
            <dd>Customers</dd>
          </dl>
        </article>
      </section>
    </section>
    <section class="home-product">
      <div class="home-product-box">
        <img src="@/images/background/product-datacenter.png" width="100%" alt="" />
      </div>
      <div class="home-product-box">
        <div class="home-product-head">
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
              <path d="M12 4.52783V21.1878L11.2 22.0978C10.09 23.3578 9.18001 23.0178 9.18001 21.3378V14.1378H6.09001C4.69001 14.1378 4.30001 13.2778 5.23001 12.2278L12 4.52783Z" fill="#017EFF"></path>
              <path opacity="0.4" d="M18.77 13.4878L12 21.1878V4.52781L12.8 3.61781C13.91 2.35781 14.82 2.69781 14.82 4.37781V11.5778H17.91C19.31 11.5778 19.7 12.4378 18.77 13.4878Z" fill="#017EFF"></path>
            </svg>
          </span>
          <span class="title">COST-EFFECTIVE AND RELIABLE PROXIES</span>
        </div>
        <h2>Affordable Premium Datacenter Proxies</h2>
        <p class="home-product-text">Shared Datacenter Proxies are concurrently accessible by several users. This shared usage model enables us to reduce server maintenance expenses, allowing us to offer these services at competitive rates, <b>starting as low as $21/month</b>. Importantly, this cost-efficiency does not compromise the quality of our offerings - all essential features are retained and fully available:</p>
        <ul class="home-product-adv datacenter">
          <li>
            <i class="el-icon-check"></i>
            <span class="title">Sticky sessions</span>
          </li>
          <li>
            <i class="el-icon-check"></i>
            </span>
            <span class="title">Automated proxy rotation</span>
          </li>
          <li>
            <i class="el-icon-check"></i>
            <span class="title">High concurrency use</span>
          </li>
        </ul>
        <a class="btn-self datacenter" href="javascript:;" @click="buySelf('/home?column=Price&category=Datacenter')">
          <svg data-v-1ee8bc0b="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" tag="i" class="v-icon notranslate v-theme--dark v-icon--size-default iconify iconify--mdi" width="1em" height="1em" viewBox="0 0 24 24" style="width: 16px; margin-right: 5px;">
          <path fill="currentColor" d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25c0-.05.01-.09.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"></path>
          </svg>Self-checkout
        </a>
      </div>
    </section>
    <section class="home-scene">
      <h3>PYPROXY Application scene</h3>
      <h4>Look how companies use our proxy solutions to boost their business</h4>
      <ul class="home-scene-list">
        <li v-for="(item, index) in scenes" :key="index" :class="item.class">{{item.title}}</li>
      </ul>
    </section>
    <customer-btn></customer-btn>
  </article>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import CustomerBtn from '@/components/layout/customer'
import { AutoBodyHeight } from '@/mixins/body-height'
import { parseTime } from '@/utils/filter.js'
import { getResidentialPriceList, getDatacenterPriceList } from '@/apis/common'

export default {
  mixins: [ AutoBodyHeight ],
  components: { CustomerBtn },
  props: {},
  data () {
    return {
      activeTab: 'Residential',
      residentials: [],
      datacenters: [],
      curResidentialIndex: null,
      curDatacenterIndex: null,
      scenes: [{
        class: 'commerce',
        title: 'E-commerce'
      }, {
        class: 'seo',
        title: 'SEO Monitoring'
      }, {
        class: 'brand',
        title: 'Brand Protection'
      }, {
        class: 'social',
        title: 'Social Media Management'
      }, {
        class: 'market',
        title: 'Market Research'
      }, {
        class: 'crawling',
        title: 'Web Scraping & Crawling'
      }]
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.common.userInfo
    })
  },
  watch: {},
  methods: {
    ...mapMutations(['setLoading', 'setCommonState']),
    init() {
      this.setLoading({
        state: true,
        text: ''
      })
      getResidentialPriceList({}, (res) => {
        // console.log(res)
        this.residentials = res
        this.setLoading({
          state: false,
          text: ''
        })
        this.handleScroll()
      }, (err) => {
        // console.log(err)
        this.setLoading({
          state: false,
          text: ''
        })
      })
      getDatacenterPriceList({}, (res) => {
        // console.log(res)
        this.datacenters = res
      }, (err) => {
        // console.log(err)
      })
    },
    handleScroll() {
      var query = this.$route.query
      // console.log(query)
      if (query.column && query.category) {
        setTimeout(() => {
          var box = null, top = 0
          if (query.column == 'Price') {
            box = document.getElementById(query.column)
            this.activeTab = query.category
          } else if (query.column == 'Product') {
            box = document.getElementById(query.column + query.category)
          }
          // console.log(box)
          top = box.offsetTop
          // console.log(top)
          window.scrollTo({
            top: top,
            behavior: 'smooth'
          })
        }, 300)
      }
    },
    tryNow() {
      this.$router.push('/login')
    },
    switchTab(tab, event) {
      // console.log(tab, event)
    },
    choosePriceItem(type, index) {
      // console.log(type, index)
      if (type == 'Residential') {
        this.curResidentialIndex = index
      } else if (type == 'Datacenter') {
        this.curDatacenterIndex = index
      }
    },
    bugPlan(item) {
      // console.log(item)
      if (this.userInfo) {
        this.setCommonState({
          label: 'curMenuId',
          value: ''
        })
        item.code = ''
        item.tip = ''
        this.setCommonState({
          label: 'order',
          value: item
        })
        this.$router.push('/confirm-order')
      } else {
        this.$router.push('/login')
      }
    },
    buySelf(link) {
      // console.log(link)
      this.$router.push(link)
      window.location.reload()
    }
  },
  created () {},
  mounted () {
    this.init()
  },
  beforeDestroy () {},
  destroyed () {}
}
</script>
