<template>
  <div class="customer-btn">
    <a :href="CONSTANT.CUSTOMER" target="_blank">
      <img src="@/images/icon/telegram.svg" width="50" height="50" alt="" />
    </a>
  </div>
</template>

<script>
import CONSTANT from '@/config/constant'

export default {
  data () {
    return {
      CONSTANT
    }
  },
  methods: {
  }
}
</script>